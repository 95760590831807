<template>
  <div v-loading="pageloading">
    <el-card style="margin-bottom:80px">
      <div class="flex" style="margin-top:20px">
        <div class="imgBox">
          <img v-if="ruform.WatermarkImgUrl" :src="imgurl+ruform.WatermarkImgUrl" style="width:100%;height:100%" alt="">
        </div>
        <div style="flex:1">
          <el-form :model="ruform" :rules="rules" ref="ruform" label-width="150px">
            <el-form-item label="水印名称：" prop="Name">
              <el-input v-model="ruform.Name" placeholder="请输入水印名称，最多20个字" :disabled="noedit>1" maxlength="20" style="width:400px"></el-input>
              <div class="grayFont">水印名称仅用于后期方便查找，不会展示在小程序商城前台</div>
            </el-form-item>
            <el-form-item label="展示时间：" prop="StartTime">
              <div class="flexRow">
                <el-date-picker v-model="ruform.StartTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" :disabled="noedit>0"
                 format="yyyy-MM-dd HH:mm:ss" placeholder="开始时间" :picker-options="options"></el-date-picker>
                <span style="margin:0px 10px">~</span>
                <el-date-picker v-model="ruform.EndTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" :disabled="noedit>1"
                 format="yyyy-MM-dd HH:mm:ss" placeholder="结束时间" :picker-options="options"></el-date-picker>
              </div>
            </el-form-item>
            <el-form-item label="选择水印：" prop="WatermarkImgUrl">
              <el-upload :action="imgapi" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
               list-type="picture-card" accept="image/*" :disabled="noedit>1">
                  <img v-if="ruform.WatermarkImgUrl" :src="imgurl+ruform.WatermarkImgUrl" style="width:100%;height:100%" alt="">
                <i v-else class="el-icon-plus"></i>
              </el-upload>
              <div class="grayFont">图片上传建议:（1）建议尺寸：800px*800px；（2）透明背景图；（3）图片大小不超过1M</div>
            </el-form-item>
            <el-form-item label="适用商品：" prop="ChoseProductList">
              <el-button type="text" @click="selshopshow=true" :disabled="noedit>1">选择商品</el-button>
              <div v-if="ruform.ChoseProductList&&ruform.ChoseProductList.length" style="width:70%">
                <el-table :data="tablelist" border>
                  <el-table-column prop="" label="商品">
                    <template slot-scope="scope">
                      <div class="flexRow">
                        <img :src="imgurl+scope.row.ImgUrl" style="width:40px;height:40px" alt="">
                        <div style="margin-left:5px" class="fontHidden2">
                          {{scope.row.Name}}
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="ProductPrice" label="价格" width="100"></el-table-column>
                  <el-table-column prop="" label="操作" width="100">
                    <template slot-scope="scope">
                      <el-button type="text" style="color:#f56c6c" @click="del(scope.row)" :disabled="noedit>1">删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <div style="margin-top:10px;text-align:right" v-if="total">
                  <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[5, 10, 15, 20]"
                    :page-size="sizepage"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                  </el-pagination>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-card>
    <el-card class="footer">
      <el-button @click="$router.go(-1)">取消</el-button>
      <el-button type="primary" @click="save('ruform')" :disabled="noedit>1">保存</el-button>
    </el-card>


    <!-- 选择商品 -->
		<el-dialog title="选择商品" :visible.sync="selshopshow" width="1300px" class="dialog">
			<selectshop :params="['KeyWord', 'ProductType', 'ProductGroupIds', 'ProductBrandId']" :selectedData="ruform.ChoseProductList"
			 @getSelectproList="getSelectproList" :goodsMaxlength='-1' :visible.sync="selshopshow" v-if="selshopshow" :tablecolumn="[1,2,4]">
			</selectshop>
		</el-dialog>

    <el-dialog title="以下商品与其他活动冲突，请重新设置" :visible.sync="errshow">
      <el-table :data="errorlist" max-height="500" border>
        <el-table-column prop="" label="商品">
          <template slot-scope="scope">
            <div class="flexRow">
              <img :src="scope.row.ImgUrlComplete" style="width:40px;height:40px" alt="">
              <div class="" style="margin-left:5px">
                {{scope.row.ProductName}}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="冲突信息">
          <template slot-scope="scope">
            <div>已设置水印：{{scope.row.ActivityName}}</div>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align:center;margin-top:20px">
        <el-button type="primary" @click="errshow=false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import config from "@/config"
import selectshop from '@/views/goods/selectgoodsElement.vue'
import {
  activityWatermarkactivityWatermarkSave,
  activityWatermarkactivityWatermarkInfo
}from "@/api/goods"
export default {
  components: {
    selectshop
  },
  data () {
    var checktime = (rule, value, callback) => {
      // console.log(this.ruform.StartTime.substring(0,10))
      if (!this.ruform.StartTime) {
        callback(new Error('请完善开始时间'));
      } else if (!this.ruform.EndTime) {
        callback(new Error('请完善结束时间'));
      } else if(new Date(this.ruform.StartTime.substring(0,10)).getTime()>new Date(this.ruform.EndTime.substring(0,10)).getTime()){
        callback(new Error('结束时间不可小于开始时间'));
      }else{
        callback()
      }
    };
    return {
      pageloading:false,
      imgurl:config.IMG_BASE,
      imgapi:config.UPLOAD_IMG,
      ruform:{
        Id:0,
        WatermarkImgUrl:'',
        Name:'',
        StartTime:'',
        EndTime:'',
        ChoseProductList:[],
        ProductIdList:[]
      },
      rules:{
        Name:[
          { required: true, message: '请输入水印名称', trigger: 'blur' },
        ],
        StartTime:[
          { required: true, validator: checktime, trigger: 'blur' }
        ],
        WatermarkImgUrl:[
          { required: true, message: '请选择水印', trigger: 'blur' },
        ],
        ChoseProductList:[
          { required: true, message: '请选择需要打水印的商品', trigger: 'blur' },
        ]
      },
      options:{
        disabledDate:(v)=>{
          return v.getTime() < new Date().getTime()-1000*60*60*24
        }
      },
      selshopshow:false,
      tablelist:[],
      currentPage:1,
      sizepage:5,
      total:null,

      noedit:0,

      errorlist:[],
      errshow:false
    }
  },
  created () {
    if(Number(this.$route.query.Id)){
      this.getdetail()
    }
  },
  methods: {
    async getdetail(){
      try{
        this.pageloading = true
        let res = await activityWatermarkactivityWatermarkInfo({
          Id:this.$route.query.Id
        })
        if(res.IsSuccess){
          let ruform = res.Result
          ruform.ChoseProductList = ruform.ChoseProductList.map(v=>{
            v.ProductId = v.Id
            return v
          })
          this.ruform = ruform
          if(new Date().getTime()>new Date(this.ruform.StartTime).getTime()){
            this.noedit = 1
          }
          if(new Date().getTime()>new Date(this.ruform.EndTime).getTime()){
            this.noedit = 2
          }
          console.log(this.noedit,new Date().getTime()>new Date(this.ruform.EndTime).getTime())
          this.getsku()
        }
      }finally{
        this.pageloading = false
      }
    },
    del(row){
      this.ruform.ChoseProductList = this.ruform.ChoseProductList.filter(v=>{
        return row.ProductId!=v.ProductId
      })
      this.getsku()
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.sizepage = e
      this.getsku()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.getsku()
    },
    getsku(){
      this.total = this.ruform.ChoseProductList.length
      this.tablelist = this.ruform.ChoseProductList.filter((v,i)=>{
        return i>=(this.currentPage-1)*this.sizepage && i<this.currentPage*this.sizepage
      })
      if((!this.tablelist||!this.tablelist.length)&&this.currentPage>1){
        this.currentPage--
        this.getsku()
      }
    },
    getSelectproList(list){
      if(!list||!list.length){
        this.$message.error('请选择需要打水印的商品')
        return
      }
      this.selshopshow = false
      this.ruform.ChoseProductList = list
      this.getsku()
    },
    save(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.tosave()
        } else {
          this.$message.error('请完善水印信息')
          return false;
        }
      });
    },
    async tosave(){
      try{
        this.pageloading = true
        let data = JSON.parse(JSON.stringify(this.ruform))
        data.ProductIdList = data.ChoseProductList.map(v=>v.ProductId)
        data.noError = true
        data.Id = this.$route.query.Id
        let res = await activityWatermarkactivityWatermarkSave(data)
        // console.log(res)
        if(res.IsSuccess){
          this.$message.success('保存成功')
          this.$router.go(-1)
        }else{
          if(res.Result&&res.Result.length){
            this.errorlist = res.Result
            this.errshow = true
          }else{
            this.$message.error(res.Message)
          }
        }
      }finally{
        this.pageloading = false
      }
    },
    handleAvatarSuccess(file){
      // console.log(file)
      this.ruform.WatermarkImgUrl = file[0]
    },
    beforeAvatarUpload(file){
      const size = file.size / 1024 / 1024 < 1;
      const testtype = ['image/png','image/jpeg', 'image/jpg']
      if (testtype.indexOf(file.type) == -1) {
        this.$message.error('仅支持上传png、jpg格式的图片');
        return false
      } else if (!size) {
        this.$message.error('图片大小请控制在1M以内');
        return false
      }
    }
  }
}
</script>

<style lang = "less" scoped>
.flex{
  display: flex;
}
.imgBox{
  background: rgb(245,245,245);
  width: 300px;height: 300px;
  flex-shrink:0;
  img{
    width:100%
  }
}
.grayFont{
  font-size: 12px;
  color: #999999;
  line-height: 1.5;
  margin-top:5px
}
.flexRow{
  display: flex;
  align-items: center;
}
.footer{
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 80px;
  margin-left: -5%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.fontHidden2{
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>